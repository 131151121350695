import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'

interface LoadingContextState {
  isLoading: boolean
  setVideoCount: (count: number) => void
  videoLoaded: () => void
}

const LoadingContext = createContext<LoadingContextState | undefined>(undefined)

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loadedCount, setLoadedCount] = useState(0)
  const [videoCount, setVideoCountState] = useState(0) // Initially 0
  const [isLoading, setIsLoading] = useState(true) // Initially true
  const [timeoutFinished, setTimeoutFinished] = useState(false)
  const minLoadingTime = 5000

  useEffect(() => {
    // Start a timer for the minimum loading time when the component mounts
    const timer = setTimeout(() => {
      setTimeoutFinished(true) // Mark the timeout as finished
      if (videoCount === 0 || loadedCount >= videoCount) {
        setIsLoading(false) // If no videos to load or all videos have loaded, set isLoading to false
      }
    }, minLoadingTime)

    return () => clearTimeout(timer) // Cleanup the timer
  }, [minLoadingTime, loadedCount, videoCount])

  useEffect(() => {
    // If all videos have loaded and the minimum loading time has passed, set isLoading to false
    if (loadedCount >= videoCount && timeoutFinished) {
      setIsLoading(false)
    }
  }, [loadedCount, videoCount, timeoutFinished])

  const videoLoaded = useCallback(() => {
    setLoadedCount((prevCount) => prevCount + 1) // Increment the loadedCount
  }, [])

  const setVideoCount = useCallback((count: number) => {
    setVideoCountState(count)
    setLoadedCount(0)
    if (count > 0) {
      setIsLoading(true) // Set isLoading to true when starting to load videos
      setTimeoutFinished(false) // Reset the timeoutFinished flag if starting a new load
    }
    // No need to set isLoading here, as the minimum loading time dictates the isLoading state
  }, [])

  return (
    <LoadingContext.Provider value={{ isLoading, setVideoCount, videoLoaded }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }
  return context
}
