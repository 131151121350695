import React, { createContext, useContext, useState } from 'react'
import type { TrackWithArtists } from '@twlvxtwlv/supabase'

interface SelectedTrackState {
  selectedTrack: TrackWithArtists | null
  setSelectedTrack: (track: TrackWithArtists | null) => void
}

const SelectedTrackContext = createContext<SelectedTrackState>({
  selectedTrack: null,
  setSelectedTrack: () => {
    throw new Error('setSelectedTrack function must be overridden')
  },
})

interface SelectedTrackProviderProps {
  children: React.ReactNode
}

export const SelectedTrackProvider = ({ children }: SelectedTrackProviderProps) => {
  const [selectedTrack, setSelectedTrack] = useState<TrackWithArtists | null>(null)

  return (
    <SelectedTrackContext.Provider value={{ selectedTrack, setSelectedTrack }}>
      {children}
    </SelectedTrackContext.Provider>
  )
}

export function useSelectedTrack() {
  return useContext(SelectedTrackContext)
}
